import React, { ReactElement } from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Avatar
} from "@material-ui/core";
import { Close, CameraAlt, KeyboardArrowRight, Check } from '@material-ui/icons';


import { TMessageAccount, TMessageAdmin } from "../ChatViewController";
import { addIconSVG, audioSVG, documentSVG, editIcon, videoSVG } from "../assets";
import { LoaderCenter } from "../../../../components/src/AdminConsoleHelper";
import { checkANDcondition, checkCondition, getNameChar, returnTruthyString } from "../../../../components/src/helper";


// Customizable Area End
export type MyComponentProps = {
  hideModal: () => void;
  logoRender: (image: string, name: string) => ReactElement;
  selectGroupImage: (event: any) => any;
  removeUser: (id: number, account_id: number, role: string) => void;
  participant_count?: number;
  image: string;
  name: string;
  media_length: string;
  media_data: any[];
  group_members: TMessageAccount[] | TMessageAdmin[];
  // chat_data: IChatData | false;
  chat_data: any
};

const ParticipantsMedia = (ele: any) => {
  return <Box className="profile-image-wrapper" key={ele?.id}>
  {
    ele.attributes.attachment_file_type === "image" && <a href={ele?.attributes?.attachments?.url} target="_blank"><img src={ele?.attributes?.attachments?.url} /></a>
  }
  {
    ele.attributes.attachment_file_type === "audio" && <span style={{
      backgroundColor: '#B3261E',
      borderRadius: '8px',
      display: 'block',
      height: '100%'
    }}>
      <a href={ele?.attributes?.attachments?.url} target="_blank"> <img className="icon" src={audioSVG} alt="" /></a>
    </span>
  }
  {
    ele.attributes.attachment_file_type === "video" && <span style={{
      backgroundColor: '#EB8F06',
      borderRadius: '8px',
      display: 'block',
      height: '100%'
    }}>
      <a href={ele?.attributes?.attachments?.url} target="_blank"><img src={videoSVG} /></a>
    </span>
  }

  {
    ele.attributes.attachment_file_type === "application" && <span style={{
      backgroundColor: '#108B88',
      borderRadius: '8px',
      display: 'block',
      height: '100%'
    }}>
      <a href={ele?.attributes?.attachments?.url} target="_blank"><img src={documentSVG} /></a>
    </span>
  }
</Box>
}

export function ParticipantComponent({
  hideModal, logoRender,
  selectGroupImage, openRemoveUserModal, image, name,
  participant_count, media_length,
  media_data, group_members, chat_data,
  isProfileImgUploading,
  openAddParticipantModal,
  handleEditGroupName,
  isEditGroupName,
  handleChangeChatname,
  chatName,
  uploadGroupName,
  participantLoading 
}: any) {
  const { is_default_chat } = chat_data?.attributes
  const memberlist = () => {

    return group_members.map((ele: any) => {

      const role: Record<string, string> = {
        "1": "Field Executive",
        "2": "",
        "super_admin": "Super Admin",
        "admin": "Admin"
      }

      const displayName = ele?.first_name ?? ele?.full_phone_number;
      const initial = displayName?.slice(0, 1)?.toUpperCase();

      return (
        <Box className="profile-member" key={ele?.id}>

          <Box className="profile-member-title-wrapper">
            <Box className="profile-member-icon">
              <h6>{initial}</h6>
            </Box>
            <Box className="profile-member-title">
              <p>{displayName}</p>
            </Box>
          </Box>
          <Box className="profile-member-right">

            <Box className="profile-member-admin">
              <p>{role[checkCondition(ele.role, ele.role, ele.role_id) as string]}</p>
            </Box>

            {chat_data && !is_default_chat && (
                <Box className="profile-member-remove" style={{ cursor: "pointer" }}>
                  <button
                    data-testid="participant_component_remove_button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (chat_data.attributes.id) { openRemoveUserModal(ele)}
                    }}>Remove</button>
                </Box>
              )
            }
          </Box>
        </Box>
      );
    });
  }

  const Participants = <>
    <Box
      // className="chat-wrapper"
      id="chat-wrapper"
      style={{
        marginInlineStart: "1.5rem",
        borderRadius: "12px 12px 0 0",
        height: "100%",
        width: "100%",
        background: "#f8f6f1"
      }}
    >
      <Box className="profile-wrapper">
        <Grid className="close-profile-button">
          <Button data-testid="text" variant="text" onClick={hideModal} ><Close /></Button>
        </Grid>
        <Box className="profile-title-wrapper">
          <Box className="profile-logo image">
            <Avatar
              style={{
                height: "100px",
                width: "100px",
                background: "#108b88",
                fontSize: "32px",
                fontWeight: 500
              } as React.CSSProperties}
              src={returnTruthyString(image)}
            >
              {getNameChar(name)}
            </Avatar>
            {!is_default_chat && <Box data-testid="upload_image" className="profile-camera" >
              {isProfileImgUploading ? <CircularProgress style={{ width: "16px", height: "16px", color: "#ccc" }} /> : <>
                <label htmlFor="selectGroupLogo"><CameraAlt style={{ cursor: "pointer" }} /></label>
                <input type="file" id="selectGroupLogo" style={{ display: "none" }} accept="image/*" onChange={selectGroupImage} />
              </>}
            </Box>}
          </Box>
          <Box className="profile-title">
            {checkANDcondition(isEditGroupName,!is_default_chat)? <input
              style={styles.textField}
              value={chatName}
              name="chat_name"
              onChange={handleChangeChatname}
              data-testid={"modal_input_topic_name"}
            /> : <h2>{name} </h2>}
            {!is_default_chat ? <>
            {isEditGroupName ? <>
              <Button style={{ minWidth: "24px" }} onClick={handleEditGroupName} disabled={!chatName}><Close style={{ width: "24px", height: "24px" }} /></Button><Button onClick={uploadGroupName} style={{ minWidth: "24px" }} disabled={!chatName}><Check style={{ width: "24px", height: "24px" }} /></Button>
              </> : <img src={editIcon} onClick={handleEditGroupName} style={{ width: "24px", height: "24px", cursor: "pointer" }} />}
            </>:<></>}
          </Box>
          <p>{participant_count} members in the group</p>
        </Box>
      </Box>

      <Box className="profile-image-media">
        <Box className="profile-image-title-wrapper">
          <span className="profile-image-title">Photos, Videos and Docs</span>
          <span className="profile-image-count"> {media_length} <KeyboardArrowRight /> </span>
        </Box>
        <Box className="profile-images" style={{ overflowY: "hidden"}}>
          {
            media_data && media_data.map((ele: any) => ParticipantsMedia(ele)
            )}
        </Box>
      </Box>

      <Box style={{ background: "#F0EBE0" }} >
        <Box className="profile-members-count" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "16px 16px 0 16px" }}>
          <p>{participant_count} members</p>
          {!is_default_chat && <Button
            variant="contained"
            data-testid="open-add-participant-button"
            style={{
              borderRadius: "24px",
              fontWeight: 500,
              lineHeight: "24px",
              display: "flex",
              alignItems: "center",
              background: "#DF5200",
              fontSize: "16px",
              color: "#FFFFFF",
              padding: "8px 24px",
              boxShadow: "none",
              fontFamily: 'IBM Plex Sans',
              textTransform: "capitalize"
            }}
            onClick={openAddParticipantModal}
          >
            <img src={addIconSVG} style={{ marginRight: '4px' }} />  Add member
          </Button>}

        </Box>
        <Box style={{ padding: "16px", overflowY: "auto", maxHeight: "400px" }}>
          {participantLoading? <LoaderCenter loadingStyle={{width:"32px",height:"32px"}}/> :<></>}
          {memberlist()}
        </Box>
      </Box>
    </Box>
  </>;

  return (
    Participants
  )
}

const styles = {
  textField: {
    fontFamily: "IBM Plex Sans",
    maxWidth: "max-content",
    borderRadius: "0.75rem",
    paddingBlock: "1rem",
    paddingInline: "1.13rem",
    fontSize: "1rem",
    resize: "none",
    backgroundColor: '#FCFAF7',
    border: "1px solid #F0EBE0",
  } as React.CSSProperties,
}